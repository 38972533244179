import React, { Component } from 'react';
import { Container, Grid, Typography, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { GetData, PostData } from '../../api/service';
import AlumniCollege from './AlumniCollege';
import { Card, CardBody } from 'reactstrap';
import EventsShort from '../../containers/EventsShort';


class AlumniDescription extends Component {
    render() {
        return (
            <div>
                {this.props.type == 'association' ? (<Library title="Alumni Events" type="ALUMNI_ASSOCIATION" />) : null}

                {this.props.type == 'support' ? (<Library title="Alumni Contribution" type="ALUMNI_CONTRIBUTION" />) : null}
                {this.props.type == 'committee' ? (<GoverningBody title="Alumni Committee" type="ALUMNI_COMMITTEE" />) : null}
                
                
                {this.props.type == 'notable' ? (<LandProperty />) : null}
               
                {this.props.type == 'list' ? (<Building12 />) : null}
                {this.props.type == 'prominent' ? (<AlumniCollege />) : null}
                {this.props.type == 'documents' ? (<Documents1 />) : null}
                {this.props.type == "events" && <EventsShort type="ALUMNI_EVENT" />}
                
            </div>
        );
    }
}

export default AlumniDescription;


export const Documents1 = (props) => {
  return (
      <div className="mb-4">
        <Typography variant="h6" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
            Important Documents
          </Typography>


          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Link</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1</TableCell>
                <TableCell>Alumni Association Registration Document</TableCell>
                <TableCell><a target="_blank" href={`/pdf/pduam-alumni-registration.pdf`}>View</a></TableCell>
              </TableRow>
            </TableBody>
          </Table>

          </div>
 
  )
}

export const SiteMap1 = (props) => {
    return (
        <div className="mb-4">
          <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
              Alumni Events
            </Typography>
            </div>
   
    )
}

export const SiteMap = (props) => {
    return (
        <div className="mb-4">
            <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
              Alumni Committee
            </Typography>

            

        </div>
    )
}

export const LandProperty = (props) => {
    return (
        <div className="mb-4">
            <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
                Notable Alumni
            </Typography>

            
        </div>
    )
}

export const Building = (props) => {
    return (
        <div className='mb-4'>
            <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
                Alumni Contribution
            </Typography>

            
        </div>
    )
}

export const Building12 = (props) => {

  const [data, setData] = React.useState([])
  const [isLoaded, setIsLoaded] = React.useState(false)

  React.useEffect(() => {
    GetData(`/getdepartments`).then((resp) => {
    setData(resp)
    setIsLoaded(true)
      
    })
  }, [])
  return (
      <div className='mb-4'>
          <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
              Alumni Members
          </Typography>


          {isLoaded && <div>
            
            <ul>
              {Array.isArray(data) && data.map((el,index) => <li key={index}>
                  <a style={{fontSize: 14}} targte="_blank" href={`/department/${el.dept_code}/alumni`}>Alumni Members of {el.dept_name}</a>
                </li>)}
              </ul>
            </div>}

          
      </div>
  )
}


export const Library = (props) => {

    const [data, setData] = React.useState([]);
    const [isLoaded, setIsLoaded] = React.useState(false)
  
    React.useEffect(() => {
      __loadFile()
    }, [])
  
    function __loadFile() {
      let d = {
        dept_code: 'PDUAM',
        type: props.type,
        year: ""
      }
  
      PostData(`/getnoticesbytypedept`, d)
        .then((resp) => {
          setData(resp)
          setIsLoaded(true)
        })
    }
  
  
  
    return (
      <>
        
        <Container className="mb-5">
          <div className="text-justify">
            <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
            {props.title}
            </Typography>
  
  
            {isLoaded && <div>
              {Array.isArray(data) && data.map((el, index) => <div key={index}>
                <Typography variant="body1" gutterBottom>
                  <p variant="body1" dangerouslySetInnerHTML={{ __html: el.description }} />
                </Typography>
  
                <br />
  
                <Grid container spacing={2}>
                  {Array.isArray(el.upload_info) && el.upload_info.map((el, index) => <Grid key={index} item xs={12} sm={12} md={12} lg={12}>
                    {el.fileType != "PDF" && <img
                      src={el.url}
                      style={{ width: '100%' }}
                    />}
  
  
                    {el.fileType == "PDF" && <a target="_blank" href={el.url}>{el.path}</a>}
                  </Grid>)}
                </Grid>
  
              </div>)}
            </div>}
          </div>
        </Container>
      </>
    );
  };


  export class GoverningBody extends Component {
    state = {
      data: [],
      isLoaded: false,
    };
  
    componentDidMount() {
      PostData(`/getmembersbytype`, {
        type: this.props.type
      }).then((resp) => {
        this.setState({
          data: resp,
          isLoaded: true,
        });
      });
    }
  
    render() {
      return (
        <div>
          
  
          <Container className="mb-5">
        
  
            <div>
              <Typography
                variant="h5"
                style={{ margin: "1em 0", fontWeight: 700 }}
                gutterBottom
              >
                {this.props.title}
              </Typography>
  
              <br />
  
              <div>
                {this.state.isLoaded && (
                  <>
                    <Grid container spacing={2}>
                      {this.state.data.map((el, index) => (
                        <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
                          <Card>
                            <CardBody
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              <div
                                style={{ display: "flex", alignItems: "center" }}
                              >
                                {Array.isArray(el.dp_url) &&
                                  el.dp_url.map((el1) => (
                                    <img src={el1} style={{ width: 150 }} />
                                  ))}
                              </div>
                              <div
                                style={{
                                  paddingLeft: 20,
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  style={{ fontWeight: 700 }}
                                  gutterBottom
                                >
                                  {el.name}
                                </Typography>
                                <Typography variant="overline">
                                  DESIGNATION
                                </Typography>
                                <Typography
                                  variant="body2"
                                  style={{ fontWeight: 700 }}
                                  gutterBottom
                                >
                                  {el.designation}
                                </Typography>
                                <br />
                                {el.email && <><Typography variant="overline">
                                  CONTACT
                                </Typography>
                                <Typography variant="body1">
                                  <a href={`mailto:${el.email.toLowerCase()}`}>
                                    {el.email.toLowerCase()}
                                  </a>
                                </Typography></>}

                                
                              </div>

                              <div
                                style={{
                                  paddingLeft: 20,
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  marginTop: 40
                                }}
                              >
                                

                                
                                {el.phone && <><Typography variant="overline">
                                  PHONE
                                </Typography>
                                <Typography variant="body1">
                                  <a href={`tel:${el.phone}`}>
                                    {el.phone}
                                  </a>
                                </Typography></>}

                                <br />
                                {el.phone && <><Typography variant="overline">
                                  DATE OF JOINING
                                </Typography>
                                <Typography variant="body1">
                                  
                                    {el.date_of_joining}
                                 
                                </Typography></>}
                              </div>
                            </CardBody>
                          </Card>
                          <br />
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
              </div>
            </div>
          </Container>
        </div>
      );
    }
  }