import React, { Component } from "react";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  Button,
} from "mdbreact";
import { Card, CardHeader } from "reactstrap";
import BreadCumb from "../Department/BreadCumb";
import { PostData } from "../../api/service";
import { Container, Typography } from "@material-ui/core";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";

// const facultyData = [
//     {
//         name: 'Dr. Jogesh Kakati',
//         desg: 'Principal',
//         qualification: 'MA, PhD',
//         email: '',
//         phone: '',
//         mobile: '',
//         specifications: '',
//         cv: '',
//         photo: ''
//     },

// ]

export default class PresidentContainer extends Component {
  state = {
    facultyData: [],
    isLoaded: false,
    dept_code: "OFF",
  };

  componentDidMount() {
    let d = {
      dept_code: "OFF",
      type: "PRESIDENT",
    };

    PostData(`/getemployeebytype`, d).then((resp) => {
      //console.log(resp)
      this.setState({
        ...resp[0],
        facultyData: resp,
        isLoaded: true,
      });
    });
  }
  render() {
    return (
      <div>
        <BreadCrumbOwn title="President's Message" />

        <Container className="mb-5">
          <br />

          {this.state.isLoaded &&
            this.state.facultyData.map((el, index) => {
              return (
                <div>
                  {/* <Card style={{ boxShadow: 'none' }}>
                            <CardHeader style={{ color: 'white', boxShadow: 'none', backgroundColor: '#1E3760', textAlign: 'center' }}> <h3 style={{color: 'inherit'}}>{el.desg}</h3></CardHeader>
                        </Card> */}
                  <div>
                        <div style={{ textAlign: "left" }}>
                          <img
                            alt=""
                            src={el.dp.length > 0 ? el.dp[0] : ""}
                            style={{
                              width: "350px",
                              boxShadow: "1px 2px 10px rgba(0,0,0,0.1)",
                              border: `solid 5px wheat`,
                            }}
                          />
                        </div>

                        {/* <h5
                          style={{
                            fontSize: "16px",
                            textAlign: "left",
                            fontWeight: 700,
                          }}
                        >
                          {el.name}
                        </h5> */}
                        <h6 style={{ fontSize: "12px", textAlign: "right" }}>
                          <a href={`mailto:${el.email}`}>{el.email}</a>
                        </h6>
                        <h6 style={{ fontSize: "12px", textAlign: "right" }}>
                          <a href={`tel:${el.phone}`}>{el.phone}</a>
                        </h6>
                        <h6 style={{ fontSize: "12px", textAlign: "right" }}>
                          <a href={`tel:${el.mobile}`}>{el.mobile}</a>
                        </h6>
                        {/* <h6 style={{ fontSize: "12px", textAlign: "right" }}>
                          <a href={`tel:${el.mobile}`}>
                            {this.state.isLoaded && this.state.id != undefined && (
                              <a
                                style={{
                                  color: "#1892FF",
                                  cursor: "pointer",
                                  fontWeight: 700,
                                  textAlign: "right",
                                }}
                                // href={`/department/${this.state.dept_code}/faculty/single?id=${this.state.id}&name=${this.state.name}`}
                                href={el.cv.length > 0 ? el.cv[0] : ""}
                              >
                                Visit Profile
                              </a>
                            )}
                          </a>
                        </h6> */}
                      </div>
                  <div>
                    <div>
                      <Typography
                        variant="h5"
                        style={{ margin: "1em 0", fontWeight: 700 }}
                        gutterBottom
                      >
                        FROM THE PRESIDENT’S DESK
                      </Typography>
                      <Typography
                        style={{ textAlign: "justify" }}
                        variant="body1"
                        gutterBottom
                      >
                       I am delighted to officially declare the commencement of the new academic session 2024-2025 at Pandit Deendayal Upadhyaya Adarsha Mahavidyalaya (PDUAM), Eraligool, Karimganj. 
                      </Typography>

                      <br />

                      <Typography
                        style={{ textAlign: "justify" }}
                        variant="body1"
                        gutterBottom
                      >We are proud to inform you that, Karimganj is among the first five districts of Assam to have been blessed by the Govt of Assam to establish a Govt Science Model Degree College, to impart science education in and around the academically backward rural regions of Eraligool. Besides imparting science education both at the Higher Secondary and the Under-graduate (UG) levels since its inception in 2017, the Mahavidyalaya has emerged as a truly  multidisciplinary higher education institution by introducing Arts stream at the UG level from the academic session 2023-2024. </Typography>
                       <br />
                      <Typography
                        style={{ textAlign: "justify" }}
                        variant="body1"
                        gutterBottom
                      >The Govt of Assam’s primary ojective behind setting up of this higher education institution is to realize the aspirations of the visionary statesman and social thinker ‘Pandit Deendayal Upadhyaya’ for the socio-economic upliftment of the rural communities. Accessing higher education is typically challenging for the underprivileged groups in most of the developing countries. Realising the critical importance of higher education, the Govt of India has taken up the responsibility of delivering higher education to the socio-economically disadvantaged section of society at their doorsteps. In view of this, the Govt of Assam has initiated the establishment of additional model degree colleges to cater to the academic needs of the rural residents who are deprived of modern science education. </Typography>
                       <br />
                      <Typography
                        style={{ textAlign: "justify" }}
                        variant="body1"
                        gutterBottom
                      >The Mahavidyalaya commenced its maiden academic journey from the academic session 2017-2018, offering Higher Secondary Science Stream and B.Sc. (both Hons. And Pass) courses. Spanning across a vast campus of 30 bighas with charming edifices and a distinct playground (a plot of five bighas), the Mahavidyalaya is located at Eraligool, 26 kms from Karimganj town, accessible by various modes of transportation. </Typography>
                       <br />

<Typography
                        style={{ textAlign: "justify" }}
                        variant="body1"
                        gutterBottom
                      >The residential quarters for the teaching and non-teaching staff, and the Girls’ hostel are currently in use. Dr. Jayasree Chakrabarty, an esteemed figure with a distinguished tenure at Cotton College, Guwahati (now Cotton University), has been appointed by the Government of Assam as the Founding Principal of the Mahavidyalaya, showcasing dynamic leadership qualities in the realm of education. </Typography>
                       <br />

<Typography
                        style={{ textAlign: "justify" }}
                        variant="body1"
                        gutterBottom
                      >The Mahavidyalaya's scope extends beyond Arts and Science education, encompassing various social and academic endeavours aimed at enhancing societal welfare and benefiting local inhabitants. We sincerely solicit the supports of the local educators, curious parents, enthusiastic students, and the general public to contribute in any capacity towards the realization of high-quality education in Karimganj district, which is the motto for establishment of this institute of higher education.</Typography>
                      <br />

                      <div>
                        {/* <div style={{ textAlign: "right" }}>
                          <img
                            alt=""
                            src={el.dp.length > 0 ? el.dp[0] : ""}
                            style={{
                              width: "250px",
                              boxShadow: "1px 2px 10px rgba(0,0,0,0.1)",
                              border: `solid 5px wheat`,
                            }}
                          />
                        </div> */}

                        <h4
                          style={{
                            fontSize: "20px",
                            textAlign: "right",
                            fontWeight: 700,
                          }}
                        >
                          {el.name}
                        </h4>
                        <h6 style={{ fontSize: "12px", textAlign: "right" }}>
                          <a href={`mailto:${el.email}`}>{el.email}</a>
                        </h6>
                        <h6 style={{ fontSize: "12px", textAlign: "right" }}>
                          <a href={`tel:${el.phone}`}>{el.phone}</a>
                        </h6>
                        <h6 style={{ fontSize: "12px", textAlign: "right" }}>
                          <a href={`tel:${el.mobile}`}>{el.mobile}</a>
                        </h6>
                        {/* <h6 style={{ fontSize: "12px", textAlign: "right" }}>
                          <a href={`tel:${el.mobile}`}>
                            {this.state.isLoaded && this.state.id != undefined && (
                              <a
                                style={{
                                  color: "#1892FF",
                                  cursor: "pointer",
                                  fontWeight: 700,
                                  textAlign: "right",
                                }}
                                // href={`/department/${this.state.dept_code}/faculty/single?id=${this.state.id}&name=${this.state.name}`}
                                href={el.cv.length > 0 ? el.cv[0] : ""}
                              >
                                Visit Profile
                              </a>
                            )}
                          </a>
                        </h6> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </Container>
      </div>
    );
  }
}
